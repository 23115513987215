/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
    font-family: "Open Sans", sans-serif;
    color: #4d4643;
}
  
a {
    color: #1b82bd;
    text-decoration: none !important;
}

a:hover {
    color: #1b82bd;
    text-decoration: none !important;
}

h1, h2, h3, h4, h5, h6 {
    font-family: "Roboto", sans-serif;
}
  
/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
    position: fixed !important;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: 15px;
    z-index: 996;
    background: #1b82bd;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    transition: all 0.4s;
}

.back-to-top i {
    font-size: 28px;
    color: #fff;
    line-height: 0;
}

.back-to-top:hover {
    background: #239be0;
    cursor: pointer;
    color: #fff;
}

.back-to-top.active {
    visibility: visible;
    opacity: 1;
}
  
/*--------------------------------------------------------------
# Disable AOS delay on mobile
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
    [data-aos-delay] {
        transition-delay: 0 !important;
    }
}
  
  /*--------------------------------------------------------------
  # Header
  --------------------------------------------------------------*/
  #header {
    background: #fff;
    transition: all 0.5s;
    z-index: 997;
    padding: 15px 0;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  }
  
  #header .logo {
    font-size: 28px;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-weight: 700;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }
  
  #header .logo a {
    color: #111;
  }
  
  #header .logo a span {
    color: #1b82bd;
    /* text-decoration: none; */
  }
  
  #header .logo img {
    max-height: 40px;
  }
  
  /*--------------------------------------------------------------
  # Header Social Links
  --------------------------------------------------------------*/
  .header-social-links {
    margin-left: 20px;
    border-left: 1px solid #c4c4c4;
  }
  
  .header-social-links a {
    color: #a0a0a0;
    display: inline-block;
    line-height: 0px;
    transition: 0.3s;
    padding-left: 20px;
  }
  
  /* .header-social-links a i {
    line-height: 0;
  } */
  
  .header-social-links a:hover {
    color: #1b82bd;
  }
  
  @media (max-width: 991px) {
    .header-social-links {
      padding: 0 15px 0 0;
      border-left: 0;
    }
  }
  
  /*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
  /**
  * Desktop Navigation 
  */
  .navbar {
    padding: 0;
  }
  
  .navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }
  
  .navbar li {
    position: relative;
  }
  
  .navbar a, .navbar span {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0 10px 30px;
    font-family: "Roboto", sans-serif;
    font-size: 13px;
    font-weight: 600;
    color: #111;
    white-space: nowrap;
    text-transform: uppercase;
    transition: 0.3s;
    cursor: pointer;
  }
  
  .navbar i{
    padding-left: 3px;
  }

  .navbar a i span {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
  }
  
  .navbar a:hover, .navbar span:hover, .navbar .active, .navbar li:hover > a{
    color: #1b82bd;
  }
  
  .navbar .dropdown ul {
    display: block;
    position: absolute;
    left: 14px;
    top: calc(100% + 30px);
    margin: 0;
    padding: 10px 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: 0.3s;
    border-top: 2px solid #1b82bd;
  }
  
  .navbar .dropdown ul li {
    min-width: 200px;
  }
  
  .navbar .dropdown ul a{
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 500;
    text-transform: none;
    color: #111;
  }
  
  .navbar .dropdown ul a i {
    font-size: 12px;
  }
  
  .navbar .dropdown ul a:hover, .navbar .dropdown ul .active:hover, .navbar .dropdown ul li:hover > a {
    color: #1b82bd;
  }
  
  .navbar .dropdown:hover > ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
  }
  
  .navbar .dropdown .dropdown ul {
    top: 0;
    left: calc(100% - 30px);
    visibility: hidden;
  }
  
  .navbar .dropdown .dropdown:hover > ul {
    opacity: 1;
    top: 0;
    left: 100%;
    visibility: visible;
  }
  
  @media (max-width: 1366px) {
    .navbar .dropdown .dropdown ul {
      left: -90%;
    }
    .navbar .dropdown .dropdown:hover > ul {
      left: -100%;
    }
  }
  
  /**
  * Mobile Navigation 
  */
  .mobile-nav-toggle {
    color: #111;
    font-size: 28px;
    cursor: pointer;
    display: none;
    line-height: 0;
    transition: 0.5s;
    z-index: 999;
  }
  
  .mobile-nav-toggle.bi-x {
    color: #fff;
  }
  
  @media (max-width: 991px) {
    .mobile-nav-toggle {
      display: block;
    }
    .navbar ul {
      display: none;
    }
  }
  
  .navbar-mobile {
    position: fixed !important; 
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.9);
    transition: 0.3s;
    z-index: 999;
  }
  
  .navbar-mobile .mobile-nav-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
  }
  
  .navbar-mobile ul {
    display: block;
    position: absolute;
    top: 55px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 10px 0;
    background-color: #fff;
    overflow-y: auto;
    transition: 0.3s;
  }
  
  .navbar-mobile a{
    padding: 10px 20px;
    font-size: 15px;
    color: #111;
  }
  
  .navbar-mobile span{
    padding: 10px 20px;
    font-size: 15px;
    color: #111;
  }

  .navbar-mobile a:hover, .navbar-mobile .active, .navbar-mobile li:hover > a {
    color: #1b82bd;
  }
  
  .navbar-mobile .getstarted {
    margin: 15px;
  }
  
  .navbar-mobile .dropdown ul {
    position: static;
    display: none;
    margin: 10px 20px;
    padding: 10px 0;
    z-index: 99;
    opacity: 1;
    visibility: visible;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  }
  
  .navbar-mobile .dropdown ul li {
    min-width: 200px;
  }
  
  .navbar-mobile .dropdown ul a {
    padding: 10px 20px;
  }
  
  .navbar-mobile .dropdown ul a i {
    font-size: 12px;
  }
  
  .navbar-mobile .dropdown ul a:hover, .navbar-mobile .dropdown ul .active:hover, .navbar-mobile .dropdown ul li:hover > a {
    color: #1b82bd;
  }
  
  .navbar-mobile .dropdown > .dropdown-active {
    display: block;
  }
  
  /*--------------------------------------------------------------
  # Hero Section
  --------------------------------------------------------------*/
  #hero {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    overflow: hidden;
    position: relative;
  }
  
  #hero .carousel, #hero .carousel-inner, #hero .carousel-item, #hero .carousel-item::before {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
  
  #hero .carousel-container {
    z-index: 1;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: absolute;
    bottom: 60px;
    top: 70px;
    left: 55px;
    right: 55px;
  }
  
  #hero .carousel-content {
    background: rgba(4, 4, 4, 0.7);
    padding: 20px;
    color: #fff;
    border-top: 5px solid #1b82bd;
  }
  
  #hero .carousel-content h2 {
    color: #fff;
    margin-bottom: 20px;
    font-size: 28px;
    font-weight: 700;
  }
  
  #hero .btn-start-links {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 4px;
    transition: 0.5s;
    line-height: 1;
    margin: 10px;
    color: #fff;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
    border: 2px solid #1b82bd;
  }
  
  #hero .btn-start-links:hover {
    background: #1b82bd;
    color: #fff;
    text-decoration: none;
  }
  
  #hero .carousel-inner .carousel-item {
    transition-property: opacity;
    background-position: center top;
  }
  
  #hero .carousel-inner .carousel-item,
  #hero .carousel-inner .active.carousel-item-start,
  #hero .carousel-inner .active.carousel-item-end {
    opacity: 0;
  }
  
  #hero .carousel-inner .active,
  #hero .carousel-inner .carousel-item-next.carousel-item-start,
  #hero .carousel-inner .carousel-item-prev.carousel-item-end {
    opacity: 1;
    transition: 0.5s;
  }
  
  #hero .carousel-inner .carousel-item-next,
  #hero .carousel-inner .carousel-item-prev,
  #hero .carousel-inner .active.carousel-item-start,
  #hero .carousel-inner .active.carousel-item-end {
    left: 0;
    transform: translate3d(0, 0, 0);
  }
  
  #hero .carousel-control-next-icon, #hero .carousel-control-prev-icon {
    background: none;
    font-size: 30px;
    line-height: 0;
    width: auto;
    height: auto;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 50px;
    transition: 0.3s;
    color: rgba(255, 255, 255, 0.6);
    width: 54px;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  #hero .carousel-control-next-icon:hover, #hero .carousel-control-prev-icon:hover {
    background: rgba(255, 255, 255, 0.6);
    color: rgba(255, 255, 255, 0.8);
  }
  
  /* #hero .carousel-indicators li {
    cursor: pointer;
    background: #fff;
    overflow: hidden;
    border: 0;
    width: 12px;
    height: 12px;
    border-radius: 50px;
    opacity: .6;
    transition: 0.3s;
    margin: 1px;
  }
  
  #hero .carousel-indicators li.active {
    opacity: 1;
    background: #1b82bd;
  } */
  
  @media (min-width: 1024px) {
    #hero .carousel-content {
      width: 60%;
    }
    #hero .carousel-control-prev, #hero .carousel-control-next {
      width: 5%;
    }
  }
  
  @media (max-width: 992px) {
    #hero .carousel-container {
      top: 58px;
    }
    #hero .carousel-content h2 {
      margin-bottom: 15px;
      font-size: 22px;
    }
    #hero .carousel-content p {
      font-size: 15px;
    }
  }
  
  @media (max-height: 500px) {
    #hero {
      height: 120vh;
    }
  }
  
  /*--------------------------------------------------------------
  # Sections General
  --------------------------------------------------------------*/
  section {
    padding: 60px 0;
    overflow: hidden;
  }
  
  .section-bg {
    background-color: #f7f7f7;
  }
  
  .section-title {
    text-align: center;
    padding-bottom: 30px;
  }
  
  .section-title h2 {
    font-size: 32px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
  }

  .section-title h3{
    font-weight: bold;
    text-transform: uppercase;
  }
  
  .section-title h2::after {
    content: '';
    position: absolute;
    display: block;
    width: 50px;
    height: 3px;
    background: #1b82bd;
    bottom: 0;
    left: calc(50% - 25px);
  }
  
  .section-title p {
    margin-bottom: 0;
  }
  
  /*--------------------------------------------------------------
  # Breadcrumbs
  --------------------------------------------------------------*/
  .breadcrumbs {
    padding: 15px 0;
    background: #1b82bd;
    min-height: 40px;
    margin-top: 85.5px;
  }
  
  .breadcrumbs h2 {
    font-size: 26px;
    font-weight: 300;
    color: #fff;
  }
  
  .breadcrumbs ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
    color: #fff;
  }
  
  .breadcrumbs ol a {
    color: #fff;
  }
  
  .breadcrumbs ol li + li {
    padding-left: 10px;
  }
  
  .breadcrumbs ol li + li::before {
    display: inline-block;
    padding-right: 10px;
    color: #fff;
    content: "/";
  }
  
  @media (max-width: 991px) {
    .breadcrumbs {
      margin-top: 80px;
    }
    .breadcrumbs .d-flex {
      display: block !important;
    }
    .breadcrumbs ol {
      display: block;
    }
    .breadcrumbs ol li {
      display: inline-block;
    }
  }
  
  
  
  /*--------------------------------------------------------------
  # Programs
  --------------------------------------------------------------*/
  .programs .box {
    padding: 20px;
    background: #fff;
    text-align: center;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    position: relative;
    overflow: hidden;
  }
  
  .programs h3 {
    font-weight: 400;
    margin: -20px -20px 20px -20px;
    padding: 20px 15px;
    font-size: 16px;
    font-weight: 600;
    color: #847872;
    background: #f8f8f8;
  }
  
  .programs h4 {
    font-size: 36px;
    color: #1bbd36;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    margin-bottom: 20px;
  }
  
  .programs h4 sup {
    font-size: 20px;
    top: -15px;
    left: -3px;
  }
  
  .programs h4 span {
    color: #bababa;
    font-size: 16px;
    font-weight: 300;
  }
  
  .programs ul {
    padding: 0;
    list-style: none;
    color: #4d4643;
    text-align: center;
    line-height: 20px;
    font-size: 14px;
  }
  
  .programs ul li {
    padding-bottom: 16px;
  }
  
  .programs ul i {
    color: #1b82bd;
    font-size: 18px;
    padding-right: 4px;
  }
  
  .programs .na {
    color: #ccc;
    text-decoration: line-through;
  }
  
  .programs .btn-wrap {
    margin: 20px -20px -20px -20px;
    padding: 20px 15px;
    background: #f8f8f8;
    text-align: center;
  }
  
  .programs .btn-buy {
    /* background: #1bbd36; */
    display: inline-block;
    padding: 8px 35px 10px 35px;
    border-radius: 4px;
    /* color: #fff; */
    transition: none;
    font-size: 14px;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    transition: 0.3s;
  }
  
  /* .programs .btn-buy:hover {
    background: #2ae149;
  } */

  .programs .cubs h3{
    color: #fff;
    background: #f5870a;
  }

  .programs .cubs .btn-buy {
    color: #fff;
    background: #f5870a;
  }

  .programs .cubs .btn-buy:hover {
    background: #eba555;
  }

  .programs .scouts h3 {
    color: #fff;
    background: #99C221;
  }

  .programs .scouts .btn-buy {
    color: #fff;
    background: #99c221;
  }

  .programs .scouts .btn-buy:hover {
    background: #b4c977;
  }

  .programs .venturers h3 {
    color: #fff;
    background: #0078A7;
  }

  .programs .venturers .btn-buy {
    color: #fff;
    background: #0078A7;
  }

  .programs .venturers .btn-buy:hover {
    background: #5498b3;
  }

  .programs .rovers h3{
    color: #fff;
    background: #F15060;
  }

  .programs .rovers .btn-buy{
    color: #fff;
    background: #F15060;
  }

  .programs .rovers .btn-buy:hover {
    background: #e98790;
  }
  
  .programs .advanced {
    width: 200px;
    position: absolute;
    top: 18px;
    right: -68px;
    transform: rotate(45deg);
    z-index: 1;
    font-size: 14px;
    padding: 1px 0 3px 0;
    background: #1bbd36;
    color: #fff;
  }
  
  /*--------------------------------------------------------------
  # Frequently Asked Questions
  --------------------------------------------------------------*/
  .faq .faq-list {
    padding: 0 100px;
  }
  
  .faq .faq-list ul {
    padding: 0;
    list-style: none;
  }
  
  .faq .faq-list li + li {
    margin-top: 15px;
  }
  
  .faq .faq-list li {
    padding: 20px;
    background: #fff;
    border-radius: 4px;
    position: relative;
  }
  
  .faq .faq-list a {
    display: block;
    position: relative;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    padding: 0 30px;
    outline: none;
    cursor: pointer;
  }
  
  .faq .faq-list .icon-help {
    font-size: 24px;
    position: absolute;
    right: 0;
    left: 20px;
    color: lightgray;
  }
  
  .faq .faq-list .icon-show, .faq .faq-list .icon-close {
    font-size: 24px;
    position: absolute;
    right: 0;
    top: 0;
  }
  
  .faq .faq-list p {
    margin-bottom: 0;
    padding: 10px 0 0 0;
  }
  
  .faq .faq-list .icon-show {
    display: none;
  }
  
  .faq .faq-list a.collapsed {
    color: #343a40;
    transition: 0.3s;
  }
  
  .faq .faq-list a.collapsed:hover {
    color: #1bbd36;
  }
  
  .faq .faq-list a.collapsed .icon-show {
    display: inline-block;
  }
  
  .faq .faq-list a.collapsed .icon-close {
    display: none;
  }
  
  @media (max-width: 1200px) {
    .faq .faq-list {
      padding: 0;
    }
  }
  
  /*--------------------------------------------------------------
  # Contact
  --------------------------------------------------------------*/
  .contact .info-wrap {
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    padding: 30px;
  }
  
  .contact .info {
    background: #fff;
  }
  
  .contact .info i {
    font-size: 20px;
    color: #1b82bd;
    float: left;
    width: 44px;
    height: 44px;
    border: 1px solid #1b82bd;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transition: all 0.3s;
  }
  
  .contact .info h4 {
    padding: 0 0 0 60px;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #111;
  }
  
  .contact .info p {
    padding: 0 0 0 60px;
    margin-bottom: 0;
    font-size: 16px;
    overflow-wrap: anywhere;
    color: #444444;
  }
  
  .contact .info:hover i {
    background: #1b82bd;
    color: #fff;
  }
  
  /* .contact .php-email-form {
    width: 100%;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
    padding: 30px;
    background: #fff;
  }
  
  .contact .php-email-form .form-group {
    padding-bottom: 8px;
  }
  
  .contact .php-email-form .error-message {
    display: none;
    color: #fff;
    background: #ed3c0d;
    text-align: left;
    padding: 15px;
    font-weight: 600;
  }
  
  .contact .php-email-form .error-message br + br {
    margin-top: 25px;
  }
  
  .contact .php-email-form .sent-message {
    display: none;
    color: #fff;
    background: #18d26e;
    text-align: center;
    padding: 15px;
    font-weight: 600;
  }
  
  .contact .php-email-form .loading {
    display: none;
    background: #fff;
    text-align: center;
    padding: 15px;
  }
  
  .contact .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #18d26e;
    border-top-color: #eee;
    -webkit-animation: animate-loading 1s linear infinite;
    animation: animate-loading 1s linear infinite;
  }
  
  .contact .php-email-form input, .contact .php-email-form textarea {
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
    border-radius: 4px;
  }
  
  .contact .php-email-form input:focus, .contact .php-email-form textarea:focus {
    border-color: #1bbd36;
  }
  
  .contact .php-email-form input {
    height: 44px;
  }
  
  .contact .php-email-form textarea {
    padding: 10px 12px;
  }
  
  .contact .php-email-form button[type="submit"] {
    background: #1bbd36;
    border: 0;
    padding: 10px 24px;
    color: #fff;
    transition: 0.4s;
    border-radius: 4px;
  }
  
  .contact .php-email-form button[type="submit"]:hover {
    background: #2ae149;
  }
  
  @-webkit-keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } */
  
  /*--------------------------------------------------------------
  # Footer
  --------------------------------------------------------------*/
  #footer {
    color: #fff;
    font-size: 14px;
    background: #111;
  }
  
  #footer .footer-top {
    padding: 60px 0 30px 0;
    background: #1e1e1e;
  }
  
  #footer .footer-top .footer-contact {
    margin-bottom: 30px;
  }
  
  #footer .footer-top .footer-contact h3 {
    font-size: 22px;
    margin: 0 0 10px 0;
    padding: 2px 0 2px 0;
    line-height: 1;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #fff;
  }
  
  #footer .footer-top .footer-contact p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    font-family: "Roboto", sans-serif;
    color: rgba(255, 255, 255, 0.7);
  }
  
  #footer .footer-top h4 {
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    position: relative;
    padding-bottom: 12px;
  }
  
  #footer .footer-top .footer-links {
    margin-bottom: 30px;
  }
  
  #footer .footer-top .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  #footer .footer-top .footer-links ul i {
    padding-right: 2px;
    color: #1b82bd;
    font-size: 18px;
    line-height: 1;
  }
  
  #footer .footer-top .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
  }
  
  #footer .footer-top .footer-links ul li:first-child {
    padding-top: 0;
  }
  
  #footer .footer-top .footer-links ul a {
    color: rgba(255, 255, 255, 0.6);
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
  }
  
  #footer .footer-top .footer-links ul a:hover {
    text-decoration: none;
    color: #fff;
  }
  
  #footer .footer-details {
    font-size: 15px;
    color: rgba(255, 255, 255, 0.7);
  }
  
  #footer .footer-details h4 {
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    position: relative;
    padding-bottom: 12px;
  }
  
  #footer .footer-details form {
    margin-top: 30px;
    background: #fff;
    padding: 6px 10px;
    position: relative;
    border-radius: 4px 0 0 4px;
    text-align: left;
  }
  
  /* #footer .footer-details form input[type="email"] {
    border: 0;
    padding: 4px 8px;
    width: calc(100% - 100px);
  }
  
  #footer .footer-details form input[type="submit"] {
    position: absolute;
    top: 0;
    right: -4px;
    bottom: 0;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 20px;
    background: #1bbd36;
    color: #fff;
    transition: 0.3s;
    border-radius: 0 4px 4px 0;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  }
  
  #footer .footer-details form input[type="submit"]:hover {
    background: #2ae149;
  } */
  
  #footer .credits {
    padding-top: 5px;
    font-size: 13px;
    color: #fff;
  }
  
  #footer .social-links a {
    font-size: 18px;
    display: inline-block;
    background: rgba(255, 255, 255, 0.08);
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 4px;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
  }
  
  #footer .social-links a:hover {
    background: #1b82bd;
    color: #fff;
    text-decoration: none;
  }
  
